import React from "react";
import flogo from "../assets/img/f_logo.png"

class Footer extends React.Component{
    constructor(props) {
        super(props);
    }
    render(){
        return (
            <footer>
                {/*<div className="container_12">
                    <div className="grid_6 prefix_3">
                        <div className="copy">
                            &copy; 2013 | <a href="#">Privacy Policy</a> <br/> Website designed by <a
                            href="http://store.templatemonster.com?aff=netsib1" rel="nofollow">TemplateMonster.com</a>
                        </div>
                    </div>
                </div>*/}
            </footer>
        )
    }
}

export default Footer;