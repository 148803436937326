import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import {red} from '@material-ui/core/colors';
import banner from '../../assets/img/banner.png'

const useStyles = makeStyles((theme) => ({
    root: {
        //maxWidth: 345,
        //maxHeight: 50,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    bouton: {
        width: '100%'
    }
}));

export default function Banner() {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Card className={classes.root}>
            <img
                className={classes.cover}
                style={{width: '100%'}}
                src={banner}
                title="Paella dish"
            />
        </Card>
    );
}
