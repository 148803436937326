import React from "react";

class About extends React.Component{
    constructor(props) {
        super(props);
    }

    render(){
        return (
            <di>
                <h2>Page About</h2>
            </di>
        )
    }
}

export default About;