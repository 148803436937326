import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

export default function PanierIcon(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Badge badgeContent={props.quantite} color="primary">
                <ShoppingBasket />
            </Badge>
        </div>
    );
}
