export default class LocalData {
    static products = "products"
    static placeId = "placeId"

    static ajouterAuPanier(product) {
        const panier = JSON.parse(localStorage.getItem(LocalData.products))
        if (panier != null) {
            if (!this.isProductInCart(product)) {
                panier.push(product)
                localStorage.setItem(LocalData.products, JSON.stringify(panier))
            }
        } else {
            localStorage.setItem(LocalData.products, "[" + JSON.stringify(product) + "]")
        }
    }

    static getPanier() {
        const pan = localStorage.getItem(LocalData.products)
        if (pan != null)
            return (JSON.parse(pan)).reverse()

        return []
    }

    static getTotal() {
        const panier = this.getPanier()
        let tot = 0
        panier.forEach((p) => {
            tot += p.prix * p.quantite
        })

        return tot
    }

    static editProductQuantityById(id, quantite) {
        const panier = JSON.parse(localStorage.getItem(LocalData.products))
        if (panier != null) {
            panier.forEach((elt, index) => {
                if (elt.id === id) {
                    panier[index].quantite = quantite
                    localStorage.setItem(this.products, JSON.stringify(panier))
                    return true
                }
            })

            return false
        }
    }

    static deleteProduct(id) {
        const panier = JSON.parse(localStorage.getItem(LocalData.products))
        panier.forEach((p, index) => {
            if (p.id === id) {
                panier.splice(index, 1)
            }
        })
        localStorage.setItem(this.products, JSON.stringify(panier))
    }

    static isProductInCart(product) {
        const panier = this.getPanier()
        let isIn = false
        if (panier != null) {
            panier.forEach((elt) => {
                if (elt.id === product.id) {
                    isIn = true
                }
            })
        }

        return isIn
    }

    static emptyCart(){
        const panier = this.getPanier()
        panier.forEach((elt) => {
            this.deleteProduct(elt.id)
        })
    }

    static setPlaceId(placeId){
        localStorage.setItem(this.placeId, placeId)
    }

    static getPlaceId(){
        return localStorage.getItem(this.placeId)?? 1
    }

    static formaterPrix(price) {
        return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
}