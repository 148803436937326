import Api from "../repositories/api";
import LocalData from "../repositories/local-data";

class MenuController {

    getMenus() {
        const myHeaders = new Headers()
        myHeaders.set("Accept", "application/json")
        myHeaders.set("Content-Type", "application/json")
        return fetch(Api.menus, {headers: myHeaders})
            .then(res => res.json());
    }

    sendCommande(restaurantSlug, numeroPlace) {
        const myHeaders = new Headers()
        myHeaders.set("Accept", "application/json")
        myHeaders.set("Content-Type", "application/json")
        //myHeaders.set("Access-Control-Allow-Origin", "*")
        console.log(
            JSON.stringify({
                menus: LocalData.getPanier(),
                restaurant_id: restaurantSlug,
                numero_place: "rome1",
                total: LocalData.getTotal()
            })
        )


        //return fetch("https://jsonplaceholder.typicode.com/posts", {
        return fetch(Api.commandes, {
            method: 'POST',
            crossDomain: true,
            //mode: 'no-cors',
            //redirect: "error",
            body:
                JSON.stringify({
                    menus: LocalData.getPanier(),
                    restaurant_id: restaurantSlug,
                    numero_place: "rome1",
                    total: LocalData.getTotal()
                }),
            headers: {'Content-Type':'application/json'}
        }).then(res => res.json())
    }
}

export default MenuController