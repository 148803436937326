import * as React from "react";
import Home from "./Home";
import MenuController from "../controllers/MenuController";
import LocalData from "../repositories/local-data";

export default class Accueil extends React.Component {
    constructor(props) {
        super(props);
        this.state = {isLoading: false, items: [], menus: [], paniers: [], value: 0}
    }

    render() {

        return (
            <Home isLoading={this.state.isLoading} menus={this.state.menus} categories={this.state.items}/>
        )
    }

    componentDidMount() {
        let menuController = new MenuController()
        this.setState({isLoading: true})

        this.props.match ?
            LocalData.setPlaceId(this.props.match.params.id)
            : LocalData.setPlaceId(1)
        console.log("****************** place start ******************")
        console.log(LocalData.getPlaceId())
        console.log("****************** place end ******************")

        menuController.getMenus().then(
            (result) => {
                //console.log("Success")
                this.setState({
                    menus: []
                })

                let listMenu = []

                result.data.forEach((categMenus, index) => {
                    categMenus.menus.forEach((menu, index) => {
                        //console.log(menu)
                        menu.quantite = 1
                        listMenu.push(menu)
                    })
                })

                this.setState({
                    isLoading: false,
                    items: result.data,
                    menus: listMenu
                });

                //console.log('***** Place Id *****')
                //console.log(LocalData.getPlaceId())
                //console.log('***** Liste des produits *****')
                //console.log(this.state.menus)
            },
            (error) => {
                //console.log("Erreur")
                //console.log(error)
                this.setState({
                    isLoading: false,
                    error: error,
                    menus: []
                });
            }
        )
    }
}