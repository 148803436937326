import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import {red} from '@material-ui/core/colors';
import Add from '@material-ui/icons/AddCircle';
import Minus from '@material-ui/icons/RemoveCircle';
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import Button from "@material-ui/core/Button";
import LocalData from "../../repositories/local-data";

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    bouton: {
        width: '100%'
    }
}));

export default function ModalProduct(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [expanded, setExpanded] = React.useState(false);
    const [product, setProduct] = React.useState(props.produit);
    const [quantite, setQuantite] = React.useState(1);

    const editQuantity = (a) => {
        if (a === "add") {
            setQuantite(quantite + 1)
        } else {
            if (quantite > 1) {
                setQuantite(quantite - 1)
            }
        }
    }

    const ajouterAuPanier = () => {
        //console.log("********" + Date.now() + "********")
        //console.log(props.produit, quantite)
        //console.log("********" + Date.now() + "********")
        let p = props.produit
        p.quantite = quantite
        LocalData.ajouterAuPanier(p)
        //console.log("******** Panier ----- Start " + Date.now() + "********")
        //console.log(LocalData.getPanier())
        //console.log("******** Panier ----- End " + Date.now() + "********")
        props.refreshPanier()
        props.onCloseModal()
        props.showSnackbar("Produit ajouté au panier", "success")
        props.setOpen()
    }

    return (
        <Card className={classes.root}>
            <CardHeader
                title={product.nom}
            />
            <CardMedia
                className={classes.media}
                image={product.image}
                title="Paella dish"
            />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component={'div'}>
                    {product.description}
                </Typography>
                <Typography variant="h5" color="primary" component={'div'}>
                    {LocalData.formaterPrix(product.prix)} Fcfa
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <div className={classes.controls}>
                    <IconButton aria-label="previous" onClick={() => editQuantity("minus")}>
                        <Minus/>
                    </IconButton>
                    <IconButton>
                        {LocalData.formaterPrix(product.prix * quantite)} Fcfa (x{quantite})
                    </IconButton>
                    <IconButton aria-label="next" onClick={() => editQuantity("add")}>
                        <Add/>
                    </IconButton>
                </div>
            </CardActions>
            <Button
                onClick={ajouterAuPanier}
                variant="contained"
                color="secondary"
                className={classes.bouton}
                startIcon={<ShoppingBasket/>}>
                Ajouter au panier
            </Button>
        </Card>
    );
}
