import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Menu from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from "@material-ui/core/Paper";
import Product from "./elements/Product";
import LocalData from "../repositories/local-data";
import ProductPanier from "./elements/ProductPanier";
import PanierIcon from "./elements/PanierIcon";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import Button from "@material-ui/core/Button";
import MenuController from "../controllers/MenuController";
import {green} from '@material-ui/core/colors';
import anime from 'animejs/lib/anime.es.js';
import RotateRightSharpIcon from '@material-ui/icons/RotateRightSharp';
import Banner from "./elements/Banner";
import EmptyButton from "./elements/EmptyButton";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-prevent-tabpanel-${index}`}
            aria-labelledby={`scrollable-prevent-tab-${index}`}
            {...other}>
            {value === index && (
                <Box p={3}>
                    <Typography component={'div'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        '& > *': {
            margin: theme.spacing(0),
        },
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    menupanel: {
        paddingTop: 50,
        paddingBottom: 50,
        paddingLeft: 0,
        paddingRight: 0,
    },
    appBar: {
        top: 'auto',
        bottom: 0,
        paddingTop: 10,
    },
    bouton: {
        width: '100%',
        textAlign: 'center'
    },
    boutondisable: {
        width: '100%',
        opacity: 0.5
    },
    prix: {
        fontWeight: 'bold'
    }
}));


export default function Home(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [isValidatingCommande, setValidatingCommande] = React.useState(false);
    const [commandeEnvoyee, setcommandeEnvoyee] = React.useState(false);
    const [panier, setPanier] = React.useState(LocalData.getPanier());
    const [total, setTotal] = React.useState(LocalData.getTotal());

    const [open, setOpen] = React.useState(props.open);
    const [snackMsg, setSnackMsg] = React.useState("Msg");
    const [msgType, setMsgType] = React.useState("success");

    const showSnackbar = (msg, msgType) => {
        setSnackMsg(msg)
        setMsgType(msgType)
        setOpen(true)
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const refreshPanier = () => {
        setPanier(LocalData.getPanier())
        setTotal(LocalData.getTotal())
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const validerCommande = () => {
        let menuController = new MenuController()
        if (!(panier.length === 0 || isValidatingCommande)) {
            setValidatingCommande(true)
            menuController.sendCommande('5efd4d4223de11593658690', '22').then(
                (result) => {
                    console.log("Success Req")
                    console.log(result)
                    setValidatingCommande(false)
                    setcommandeEnvoyee(true)
                    LocalData.emptyCart()
                    refreshPanier()
                },
                (error) => {
                    console.log("Error Req")
                    console.log(error)
                    setValidatingCommande(false)
                    setcommandeEnvoyee(false)
                }
            )
        }
    }

    const filtrerProduits = (clssNm) => {
        let allproducts = document.getElementsByClassName("menu-item")
        let selectedproducts = document.getElementsByClassName(clssNm.replace('.', ""))

        //console.log('Filtering...' + Date.now())

        anime({
            targets: '.menu-container',
            duration: 200,
            scale: 0
        }).finished.then(
            () => {
                for (let i = 0; i < allproducts.length; i++) {
                    allproducts[i].style.display = 'none';
                }
                for (let i = 0; i < selectedproducts.length; i++) {
                    selectedproducts[i].style.display = 'block';
                }
                anime({
                    targets: '.menu-container',
                    //easing: 'easeInOutQuad',
                    duration: 400,
                    scale: 1
                }).finished.then(
                    () => {

                    }
                )
            }
        )
    }

    return (
        <div className={classes.root}>
            <AppBar position="fixed" color={"inherit"}>
                <Paper square className={classes.root}>
                    <Tabs className={"col-lg-6 p-0"} style={{margin: '0 auto'}}
                          value={value}
                          onChange={handleChange}
                          variant="fullWidth"
                          indicatorColor="secondary"
                          textColor="secondary"
                          aria-label="icon label tabs example">
                        <Tab icon={<Menu/>} label="MENU"/>
                        <Tab icon={<PanierIcon quantite={panier.length}/>} label="PANIER"/>
                    </Tabs>
                </Paper>
            </AppBar>

            {/* Menu Tab */}

            <TabPanel value={value} index={0} className={classes.menupanel}>
                {
                    !props.isLoading ?
                        <div>
                            <main id="main">
                                <section id="menu" className="menu">
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className="tab-pane fade show active" id="pills-home" role="tabpanel"
                                             aria-labelledby="pills-home-tab">
                                            <div className="container-fluid p-0">
                                                <Banner/>
                                                <div className="section-title">
                                                    <h2>Découvrez nos savoureux <span>Menus</span></h2>
                                                </div>

                                                <div className="row">
                                                    <div className="col-lg-12 d-flex justify-content-center">
                                                        <ul id="menu-flters">
                                                            {
                                                                props.categories.map(item => (
                                                                    <li key={item.id} data-filter={'.filter-' + item.id}
                                                                        onClick={() => filtrerProduits('.filter-' + item.id)}
                                                                    >
                                                                        {item.nom}
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </main>

                            <div className={'menu-container'}>
                                {props.menus.map(item => (
                                    <div className={"menu-item filter-" + item.menu_id} key={item.id}>
                                        <Product key={item.id} data-filter={'.filter-' + item.menu_id}
                                                 produit={item} refreshPanier={refreshPanier}
                                                 showSnackbar={showSnackbar}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div> :
                        <main id="main">
                            <section id="menu" className="menu">
                                <div className="text-center px-5 ">
                                    <h3 style={{marginTop: 15}}>Chargement...</h3>
                                    <img src={'https://thumbs.gfycat.com/GrimyPlainKakarikis-size_restricted.gif'}
                                         className={'mx-auto d-block imag'}/>
                                </div>
                            </section>
                        </main>
                }
            </TabPanel>

            {/* Panier Tab */}
            <TabPanel value={value} index={1} className={classes.menupanel}>
                {
                    panier.length > 0 ?
                        <div>
                            <EmptyButton refreshPanier={refreshPanier} showSnackbar={showSnackbar} />
                            {panier.map(item => (
                                <ProductPanier key={item.id} data-filter={'.filter-' + item.id}
                                               produit={item} refreshPanier={refreshPanier} showSnackbar={showSnackbar}
                                />
                            ))}
                        </div>
                        :
                        <main id="main">
                            <section id="menu" className="menu">
                                <div className="text-center px-5 ">
                                    <h3 style={{marginTop: 15}}>Votre panier est vide</h3>
                                    <img src={'https://modevandewalle.be/assets/images/empty-cart.png'}
                                         className={'mx-auto d-block imag'}/>
                                </div>
                            </section>
                        </main>
                }
            </TabPanel>


            <AppBar position="fixed" className={classes.appBar} color={"inherit"} elevation={15}>
                {commandeEnvoyee && panier.length === 0 ?
                    <div style={{margin: '0 auto'}} className={"col-lg-6 p-0"}>
                        <Typography component={'div'} className={classes.bouton}> <CheckCircleOutline fontSize={'large'}
                                                                                                      style={{color: green[500]}}/> Envoyé
                            avec succès </Typography>
                    </div>
                    :
                    <div style={{margin: '0 auto'}} className={"col-lg-6 p-0"}>
                        <Typography component={'div'}> Total: <span data-id={'A'}
                                                                    className={classes.prix}>{LocalData.formaterPrix(total)} Fcfa </span></Typography>
                        <Button
                            onClick={validerCommande}
                            variant="contained"
                            color="secondary"
                            startIcon={isValidatingCommande ? <RotateRightSharpIcon className={'rotating'}/> : ""}
                            className={(panier.length === 0 || isValidatingCommande) ? classes.boutondisable : classes.bouton}>
                            {isValidatingCommande ? 'Envoi en cours' : 'Valider ma commande'}
                        </Button>
                    </div>
                }
                <Typography>Tous droit réservés. Design by <a
                    href={'https://www.innovebox.com'}>Innovebox</a></Typography>
            </AppBar>

            <div className={classes.root}>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity={msgType}>
                        {snackMsg}
                    </Alert>
                </Snackbar>
            </div>
        </div>
    );
}
