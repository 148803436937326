import React from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton";
import Add from '@material-ui/icons/AddCircle';
import Minus from '@material-ui/icons/RemoveCircle';
import LocalData from "../../repositories/local-data";
import DeleteIcon from "./DeleteIcon";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginTop: 15,
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 100,
        flex: '1 0 auto',
        borderRadius: 15,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    title: {
        fontWeight: 'bold'
    }
}));

export default function ProductPanier(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [quantite, setQuantite] = React.useState(props.produit.quantite);

    const ajouterAuPanier = () => {

    }

    const editQuantity = (a) => {
        if (a === "add") {
            const q = quantite + 1
            setQuantite(q)
            LocalData.editProductQuantityById(props.produit.id, q)
            props.refreshPanier()
        } else {
            if (quantite > 1) {
                const q = quantite - 1
                setQuantite(q)
                LocalData.editProductQuantityById(props.produit.id, q)
                props.refreshPanier()
            }
        }
    }

    return (
        <Card className={classes.root}>
            <div><DeleteIcon produit={props.produit} refreshPanier={props.refreshPanier}
                             showSnackbar={props.showSnackbar}/></div>
            <div className={classes.details}>
                <CardContent className={classes.content}>
                    <div className={classes.controls}>
                        <img src={props.produit.image} className={classes.cover}/>
                        <div className={"px-2"}>
                            <Typography className={classes.title} component={'div'}>
                                {props.produit.nom}
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary" component={'div'}>
                                {LocalData.formaterPrix(props.produit.prix)} Fcfa
                            </Typography>
                        </div>
                    </div>
                </CardContent>
                <div className={classes.controls}>
                    <Minus onClick={() => editQuantity("minus")}/>
                    <IconButton aria-label="play/pause">
                        <Typography variant="body2" color="textSecondary" component={'div'}>
                            {quantite}
                        </Typography>
                    </IconButton>
                    <Add onClick={() => editQuantity("add")}/>
                    <Typography variant="body2" color="textSecondary" className={'px-2'} component={'div'}>
                        Sous-total: <span data-id={'A'} style={{fontWeight: "bold", color: 'black'}}>{LocalData.formaterPrix(props.produit.prix * quantite)} Fcfa</span>
                    </Typography>
                </div>
            </div>
        </Card>
    );
}
