import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LocalData from "../../repositories/local-data";
import {makeStyles} from "@material-ui/core/styles";
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

const useStyles = makeStyles((theme) => ({
    boutonsup: {
        background: "grey",
        color: "white"
    }
}));

export default function EmptyButton(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const emptyCart = () => {
        setOpen(false);
        LocalData.emptyCart()
        props.refreshPanier()
        props.showSnackbar("Le panier a été vidé avec succès!", "success")
    };

    return (
        <div>
            <Button
                onClick={handleClickOpen}
                variant="outlined"
                color="secondary"
                fullWidth={true}
                style={{marginTop: 15}}
                startIcon={<HourglassEmptyIcon/>}>
                Vider le panier
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Voulez-vous vraiment vider le panier?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="default">
                        Annuler
                    </Button>
                    <Button onClick={emptyCart} color="default" autoFocus>
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
