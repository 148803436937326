import React from "react";
import logo from "../assets/img/logo.png"

class Header extends React.Component{
    constructor(props) {
        super(props);
    }
    render(){
        return (
            <header>
                <div className="container_12">
                    <div className="grid_12">
                        <div className="socials">
                            <a href="#"></a>
                            <a href="#"></a>
                            <a href="#"> </a>
                            <a href="#" className="last"></a>
                        </div>
                        <h1><a href="index.html"><img src={logo} alt="Boo House"/></a></h1>
                        <div className="menu_block">


                            <nav id="bt-menu" className="bt-menu" style={{display: 'none'}}>
                                <a href="#" className="bt-menu-trigger"><span>Menu</span></a>
                                <ul>
                                    <li className="bt-icon "><a href="index.html">Home</a></li>
                                    <li className="bt-icon"><a href="index-1.html">About</a></li>
                                    <li className="current bt-icon"><a href="index-2.html">Menu</a></li>
                                    <li className="bt-icon"><a href="index-3.html">Blog</a></li>
                                    <li className="bt-icon"><a href="index-4.html">Reservation</a></li>
                                    <li className="bt-icon"><a href="index-5.html">Contacts</a></li>
                                </ul>
                            </nav>

                            <div className="clear"></div>
                        </div>
                        <div className="clear"></div>
                    </div>
                </div>
            </header>
        )
    }
}

export default Header;