import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import About from "./views/About";
import Dashboard from "./views/Dashboard";
import Accueil from "./views/Accueil";
import Header from "./views/Header";
import Footer from "./views/Footer";

class App extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            brand: "Ford",
            model: "Mustang",
            color: "red",
            year: 1964
        };
    }
    changeColor = () => {
        this.setState({color: "chargement.."});
    }
    render() {
        return (
            <Router>
                <div>
                    <Route exact path="/">
                        <Accueil />
                    </Route>
                    <Route path="/:id"
                           render={(props) => <Accueil {...props} />}/>
                    <Footer />
                </div>
            </Router>
        /*
        <Router>

          A <Switch> looks through all its children <Route>
          elements and renders the first one whose path
          matches the current URL. Use a <Switch> any time
          you have multiple routes, but you want only one
          of them to render at a time

                    <Switch>
                        <Route exact path="/">
                            <Accueil />
                        </Route>
                        <Route path="/:id"
                               render={(props) => <Accueil {...props} />}/>
                        <Route path="/about">
                            <About />
                        </Route>
                        <Route path="/dashboard">
                            <Dashboard />
                        </Route>
                    </Switch>
            </Router>
            */
        );
    }
}

export default App;