import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import ProductModalButton from "./ProductModalButton";
import LocalData from "../../repositories/local-data";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        marginTop: 15,
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 150,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
}));

export default function Product(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const showSnackbar = () => {
        setOpen(true)
    }

    return (
        <Card className={classes.root}>
            <img
                className={classes.cover}
                src={props.produit.image}
                title="Live from space album cover"
            />
            <div className={classes.details}>
                <CardContent className={classes.content}>
                    <Typography component={'div'} >
                        {props.produit.nom}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary" component={'div'}>
                        {LocalData.formaterPrix(props.produit.prix)} Fcfa
                    </Typography>
                </CardContent>
                <div className={classes.controls}>
                    <ProductModalButton produit={props.produit} refreshPanier={props.refreshPanier} setOpen={showSnackbar}
                                        showSnackbar={props.showSnackbar}/>
                </div>
            </div>
        </Card>
    );
}
