import React from "react";

class Dashboard extends React.Component{
    constructor(props) {
        super(props);
    }
    render(){
        return (
            <di>
                <h2>Page Dashboard</h2>
            </di>
        )
    }
}

export default Dashboard;