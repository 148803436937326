import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import LocalData from "../../repositories/local-data";
import Delete from "@material-ui/icons/Delete";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    boutonsup: {
        background: "grey",
        color: "white"
    }
}));

export default function DeleteButton(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const deleteProduct = () => {
        setOpen(false);
        LocalData.deleteProduct(props.produit.id)
        props.refreshPanier()
        props.showSnackbar("Produit supprimé!", "info")
    };

    return (
        <div>
            <Button
                onClick={handleClickOpen}
                variant="contained"
                color="secondary"
                className={classes.boutonsup}
                startIcon={<Delete/>}>
                Supprimer
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Voulez-vous vraiment supprimer ce produit du panier?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="default">
                        Annuler
                    </Button>
                    <Button onClick={deleteProduct} color="default" autoFocus>
                        Confirmer
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
