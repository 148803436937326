import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import Button from "@material-ui/core/Button";
import ModalProduct from "./ModalProduct";
import LocalData from "../../repositories/local-data";
import DeleteButton from "./DeleteButton";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    bouton: {
        background: "grey",
        color: "white"
    }
}));

export default function ProductModalButton(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            {
                LocalData.isProductInCart(props.produit) ?
                    <DeleteButton produit={props.produit} refreshPanier={props.refreshPanier} showSnackbar={props.showSnackbar}/>
                    :
                    <Button
                        onClick={handleOpen}
                        variant="contained"
                        color="secondary"
                        className={classes.button}
                        startIcon={<ShoppingBasket/>}>
                        Ajouter
                    </Button>
            }
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>

                <Fade in={open}>
                    <ModalProduct produit={props.produit} onCloseModal={handleClose} refreshPanier={props.refreshPanier}
                                  setOpen={props.setOpen}
                                  showSnackbar={props.showSnackbar}/>
                </Fade>
            </Modal>
        </div>
    );
}
